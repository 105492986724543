import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Goal
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMTNWMmw4IDQtOCA0IiAvPgogIDxwYXRoIGQ9Ik0yMC41NSAxMC4yM0E5IDkgMCAxIDEgOCA0Ljk0IiAvPgogIDxwYXRoIGQ9Ik04IDEwYTUgNSAwIDEgMCA4LjkgMi4wMiIgLz4KPC9zdmc+) - https://lucide.dev/icons/goal
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Goal = createLucideIcon('Goal', [
  ['path', { d: 'M12 13V2l8 4-8 4', key: '5wlwwj' }],
  ['path', { d: 'M20.55 10.23A9 9 0 1 1 8 4.94', key: '5988i3' }],
  ['path', { d: 'M8 10a5 5 0 1 0 8.9 2.02', key: '1hq7ot' }],
]);

export default Goal;
