import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FilePieChart
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTYgMjJoMmEyIDIgMCAwIDAgMi0yVjcuNUwxNC41IDJINmEyIDIgMCAwIDAtMiAydjMiIC8+CiAgPHBvbHlsaW5lIHBvaW50cz0iMTQgMiAxNCA4IDIwIDgiIC8+CiAgPHBhdGggZD0iTTQuMDQgMTEuNzFhNS44NCA1Ljg0IDAgMSAwIDguMiA4LjI5IiAvPgogIDxwYXRoIGQ9Ik0xMy44MyAxNkE1LjgzIDUuODMgMCAwIDAgOCAxMC4xN1YxNmg1LjgzWiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/file-pie-chart
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FilePieChart = createLucideIcon('FilePieChart', [
  [
    'path',
    { d: 'M16 22h2a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v3', key: 'zhyrez' },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'M4.04 11.71a5.84 5.84 0 1 0 8.2 8.29', key: 'f1t5jc' }],
  ['path', { d: 'M13.83 16A5.83 5.83 0 0 0 8 10.17V16h5.83Z', key: '7q54ec' }],
]);

export default FilePieChart;
