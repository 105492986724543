import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FolderTree
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTMgMTBoN2ExIDEgMCAwIDAgMS0xVjZhMSAxIDAgMCAwLTEtMWgtMi41YTEgMSAwIDAgMS0uOC0uNGwtLjktMS4yQTEgMSAwIDAgMCAxNSAzaC0yYTEgMSAwIDAgMC0xIDF2NWExIDEgMCAwIDAgMSAxWiIgLz4KICA8cGF0aCBkPSJNMTMgMjFoN2ExIDEgMCAwIDAgMS0xdi0zYTEgMSAwIDAgMC0xLTFoLTIuODhhMSAxIDAgMCAxLS45LS41NWwtLjQ0LS45YTEgMSAwIDAgMC0uOS0uNTVIMTNhMSAxIDAgMCAwLTEgMXY1YTEgMSAwIDAgMCAxIDFaIiAvPgogIDxwYXRoIGQ9Ik0zIDN2MmMwIDEuMS45IDIgMiAyaDMiIC8+CiAgPHBhdGggZD0iTTMgM3YxM2MwIDEuMS45IDIgMiAyaDMiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/folder-tree
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FolderTree = createLucideIcon('FolderTree', [
  [
    'path',
    {
      d: 'M13 10h7a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-2.5a1 1 0 0 1-.8-.4l-.9-1.2A1 1 0 0 0 15 3h-2a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1Z',
      key: '10jzg2',
    },
  ],
  [
    'path',
    {
      d: 'M13 21h7a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-2.88a1 1 0 0 1-.9-.55l-.44-.9a1 1 0 0 0-.9-.55H13a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1Z',
      key: '1b9nqm',
    },
  ],
  ['path', { d: 'M3 3v2c0 1.1.9 2 2 2h3', key: '1wqwis' }],
  ['path', { d: 'M3 3v13c0 1.1.9 2 2 2h3', key: '1bqeom' }],
]);

export default FolderTree;
