import createLucideIcon from '../createLucideIcon';

/**
 * @component @name ServerCog
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNSAxMEg0YTIgMiAwIDAgMS0yLTJWNGEyIDIgMCAwIDEgMi0yaDE2YTIgMiAwIDAgMSAyIDJ2NGEyIDIgMCAwIDEtMiAyaC0xIiAvPgogIDxwYXRoIGQ9Ik01IDE0SDRhMiAyIDAgMCAwLTIgMnY0YTIgMiAwIDAgMCAyIDJoMTZhMiAyIDAgMCAwIDItMnYtNGEyIDIgMCAwIDAtMi0yaC0xIiAvPgogIDxwYXRoIGQ9Ik02IDZoLjAxIiAvPgogIDxwYXRoIGQ9Ik02IDE4aC4wMSIgLz4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIzIiAvPgogIDxwYXRoIGQ9Ik0xMiA4djEiIC8+CiAgPHBhdGggZD0iTTEyIDE1djEiIC8+CiAgPHBhdGggZD0iTTE2IDEyaC0xIiAvPgogIDxwYXRoIGQ9Ik05IDEySDgiIC8+CiAgPHBhdGggZD0ibTE1IDktLjg4Ljg4IiAvPgogIDxwYXRoIGQ9Ik05Ljg4IDE0LjEyIDkgMTUiIC8+CiAgPHBhdGggZD0ibTE1IDE1LS44OC0uODgiIC8+CiAgPHBhdGggZD0iTTkuODggOS44OCA5IDkiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/server-cog
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const ServerCog = createLucideIcon('ServerCog', [
  [
    'path',
    {
      d: 'M5 10H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-1',
      key: '1qm4no',
    },
  ],
  [
    'path',
    {
      d: 'M5 14H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-1',
      key: '1lpaho',
    },
  ],
  ['path', { d: 'M6 6h.01', key: '1utrut' }],
  ['path', { d: 'M6 18h.01', key: 'uhywen' }],
  ['circle', { cx: '12', cy: '12', r: '3', key: '1v7zrd' }],
  ['path', { d: 'M12 8v1', key: '1rj8u4' }],
  ['path', { d: 'M12 15v1', key: '1ovrzm' }],
  ['path', { d: 'M16 12h-1', key: '1qpdyp' }],
  ['path', { d: 'M9 12H8', key: '1l15iv' }],
  ['path', { d: 'm15 9-.88.88', key: '3hwatj' }],
  ['path', { d: 'M9.88 14.12 9 15', key: '13ldc9' }],
  ['path', { d: 'm15 15-.88-.88', key: '45priv' }],
  ['path', { d: 'M9.88 9.88 9 9', key: '1ladhj' }],
]);

export default ServerCog;
