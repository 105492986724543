import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Clapperboard
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAxMXY4YTIgMiAwIDAgMCAyIDJoMTJhMiAyIDAgMCAwIDItMnYtOEg0WiIgLz4KICA8cGF0aCBkPSJtNCAxMS0uODgtMi44N2EyIDIgMCAwIDEgMS4zMy0yLjVsMTEuNDgtMy41YTIgMiAwIDAgMSAyLjUgMS4zMmwuODcgMi44N0w0IDExLjAxWiIgLz4KICA8cGF0aCBkPSJtNi42IDQuOTkgMy4zOCA0LjIiIC8+CiAgPHBhdGggZD0ibTExLjg2IDMuMzggMy4zOCA0LjIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/clapperboard
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Clapperboard = createLucideIcon('Clapperboard', [
  [
    'path',
    { d: 'M4 11v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8H4Z', key: '1hxvyx' },
  ],
  [
    'path',
    {
      d: 'm4 11-.88-2.87a2 2 0 0 1 1.33-2.5l11.48-3.5a2 2 0 0 1 2.5 1.32l.87 2.87L4 11.01Z',
      key: '1vz1k2',
    },
  ],
  ['path', { d: 'm6.6 4.99 3.38 4.2', key: '192ida' }],
  ['path', { d: 'm11.86 3.38 3.38 4.2', key: 'hhucvz' }],
]);

export default Clapperboard;
