import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Bot
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iMTgiIGhlaWdodD0iMTAiIHg9IjMiIHk9IjExIiByeD0iMiIgLz4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjUiIHI9IjIiIC8+CiAgPHBhdGggZD0iTTEyIDd2NCIgLz4KICA8bGluZSB4MT0iOCIgeDI9IjgiIHkxPSIxNiIgeTI9IjE2IiAvPgogIDxsaW5lIHgxPSIxNiIgeDI9IjE2IiB5MT0iMTYiIHkyPSIxNiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/bot
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Bot = createLucideIcon('Bot', [
  [
    'rect',
    { width: '18', height: '10', x: '3', y: '11', rx: '2', key: '1ofdy3' },
  ],
  ['circle', { cx: '12', cy: '5', r: '2', key: 'f1ur92' }],
  ['path', { d: 'M12 7v4', key: 'xawao1' }],
  ['line', { x1: '8', x2: '8', y1: '16', y2: '16', key: 'h6x27f' }],
  ['line', { x1: '16', x2: '16', y1: '16', y2: '16', key: '5lty7f' }],
]);

export default Bot;
