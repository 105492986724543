import createLucideIcon from '../createLucideIcon';

/**
 * @component @name PinOff
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8bGluZSB4MT0iMiIgeDI9IjIyIiB5MT0iMiIgeTI9IjIyIiAvPgogIDxsaW5lIHgxPSIxMiIgeDI9IjEyIiB5MT0iMTciIHkyPSIyMiIgLz4KICA8cGF0aCBkPSJNOSA5djEuNzZhMiAyIDAgMCAxLTEuMTEgMS43OWwtMS43OC45QTIgMiAwIDAgMCA1IDE1LjI0VjE3aDEyIiAvPgogIDxwYXRoIGQ9Ik0xNSA5LjM0VjZoMWEyIDIgMCAwIDAgMC00SDcuODkiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/pin-off
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const PinOff = createLucideIcon('PinOff', [
  ['line', { x1: '2', x2: '22', y1: '2', y2: '22', key: 'a6p6uj' }],
  ['line', { x1: '12', x2: '12', y1: '17', y2: '22', key: '1jrz49' }],
  [
    'path',
    {
      d: 'M9 9v1.76a2 2 0 0 1-1.11 1.79l-1.78.9A2 2 0 0 0 5 15.24V17h12',
      key: '13x2n8',
    },
  ],
  ['path', { d: 'M15 9.34V6h1a2 2 0 0 0 0-4H7.89', key: 'reo3ki' }],
]);

export default PinOff;
