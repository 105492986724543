import createLucideIcon from '../createLucideIcon';

/**
 * @component @name ShoppingBasket
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtNSAxMSA0LTciIC8+CiAgPHBhdGggZD0ibTE5IDExLTQtNyIgLz4KICA8cGF0aCBkPSJNMiAxMWgyMCIgLz4KICA8cGF0aCBkPSJtMy41IDExIDEuNiA3LjRhMiAyIDAgMCAwIDIgMS42aDkuOGMuOSAwIDEuOC0uNyAyLTEuNmwxLjctNy40IiAvPgogIDxwYXRoIGQ9Im05IDExIDEgOSIgLz4KICA8cGF0aCBkPSJNNC41IDE1LjVoMTUiIC8+CiAgPHBhdGggZD0ibTE1IDExLTEgOSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/shopping-basket
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const ShoppingBasket = createLucideIcon('ShoppingBasket', [
  ['path', { d: 'm5 11 4-7', key: '116ra9' }],
  ['path', { d: 'm19 11-4-7', key: 'cnml18' }],
  ['path', { d: 'M2 11h20', key: '3eubbj' }],
  [
    'path',
    {
      d: 'm3.5 11 1.6 7.4a2 2 0 0 0 2 1.6h9.8c.9 0 1.8-.7 2-1.6l1.7-7.4',
      key: '1x2lvw',
    },
  ],
  ['path', { d: 'm9 11 1 9', key: '1ojof7' }],
  ['path', { d: 'M4.5 15.5h15', key: '13mye1' }],
  ['path', { d: 'm15 11-1 9', key: '5wnq3a' }],
]);

export default ShoppingBasket;
