import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Film
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHg9IjIiIHk9IjIiIHJ4PSIyLjE4IiByeT0iMi4xOCIgLz4KICA8bGluZSB4MT0iNyIgeDI9IjciIHkxPSIyIiB5Mj0iMjIiIC8+CiAgPGxpbmUgeDE9IjE3IiB4Mj0iMTciIHkxPSIyIiB5Mj0iMjIiIC8+CiAgPGxpbmUgeDE9IjIiIHgyPSIyMiIgeTE9IjEyIiB5Mj0iMTIiIC8+CiAgPGxpbmUgeDE9IjIiIHgyPSI3IiB5MT0iNyIgeTI9IjciIC8+CiAgPGxpbmUgeDE9IjIiIHgyPSI3IiB5MT0iMTciIHkyPSIxNyIgLz4KICA8bGluZSB4MT0iMTciIHgyPSIyMiIgeTE9IjE3IiB5Mj0iMTciIC8+CiAgPGxpbmUgeDE9IjE3IiB4Mj0iMjIiIHkxPSI3IiB5Mj0iNyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/film
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Film = createLucideIcon('Film', [
  [
    'rect',
    {
      width: '20',
      height: '20',
      x: '2',
      y: '2',
      rx: '2.18',
      ry: '2.18',
      key: 'vury4c',
    },
  ],
  ['line', { x1: '7', x2: '7', y1: '2', y2: '22', key: 't1e4qh' }],
  ['line', { x1: '17', x2: '17', y1: '2', y2: '22', key: '1tliql' }],
  ['line', { x1: '2', x2: '22', y1: '12', y2: '12', key: '1dnqot' }],
  ['line', { x1: '2', x2: '7', y1: '7', y2: '7', key: '1wdzzh' }],
  ['line', { x1: '2', x2: '7', y1: '17', y2: '17', key: '2fufxq' }],
  ['line', { x1: '17', x2: '22', y1: '17', y2: '17', key: '1xg577' }],
  ['line', { x1: '17', x2: '22', y1: '7', y2: '7', key: 'acrv2l' }],
]);

export default Film;
