import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Files
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTUuNSAySDguNmMtLjQgMC0uOC4yLTEuMS41LS4zLjMtLjUuNy0uNSAxLjF2MTIuOGMwIC40LjIuOC41IDEuMS4zLjMuNy41IDEuMS41aDkuOGMuNCAwIC44LS4yIDEuMS0uNS4zLS4zLjUtLjcuNS0xLjFWNi41TDE1LjUgMnoiIC8+CiAgPHBhdGggZD0iTTMgNy42djEyLjhjMCAuNC4yLjguNSAxLjEuMy4zLjcuNSAxLjEuNWg5LjgiIC8+CiAgPHBhdGggZD0iTTE1IDJ2NWg1IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/files
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Files = createLucideIcon('Files', [
  [
    'path',
    {
      d: 'M15.5 2H8.6c-.4 0-.8.2-1.1.5-.3.3-.5.7-.5 1.1v12.8c0 .4.2.8.5 1.1.3.3.7.5 1.1.5h9.8c.4 0 .8-.2 1.1-.5.3-.3.5-.7.5-1.1V6.5L15.5 2z',
      key: 'cennsq',
    },
  ],
  [
    'path',
    { d: 'M3 7.6v12.8c0 .4.2.8.5 1.1.3.3.7.5 1.1.5h9.8', key: 'ms809a' },
  ],
  ['path', { d: 'M15 2v5h5', key: 'qq6kwv' }],
]);

export default Files;
