import createLucideIcon from '../createLucideIcon';

/**
 * @component @name GraduationCap
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjIgMTB2Nk0yIDEwbDEwLTUgMTAgNS0xMCA1eiIgLz4KICA8cGF0aCBkPSJNNiAxMnY1YzMgMyA5IDMgMTIgMHYtNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/graduation-cap
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const GraduationCap = createLucideIcon('GraduationCap', [
  ['path', { d: 'M22 10v6M2 10l10-5 10 5-10 5z', key: '1ef52a' }],
  ['path', { d: 'M6 12v5c3 3 9 3 12 0v-5', key: '1f75yj' }],
]);

export default GraduationCap;
