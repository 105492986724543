import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Tent
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTkgMjAgMTAgNCIgLz4KICA8cGF0aCBkPSJtNSAyMCA5LTE2IiAvPgogIDxwYXRoIGQ9Ik0zIDIwaDE4IiAvPgogIDxwYXRoIGQ9Im0xMiAxNS0zIDUiIC8+CiAgPHBhdGggZD0ibTEyIDE1IDMgNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/tent
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Tent = createLucideIcon('Tent', [
  ['path', { d: 'M19 20 10 4', key: '1ak541' }],
  ['path', { d: 'm5 20 9-16', key: '11dtj9' }],
  ['path', { d: 'M3 20h18', key: '1l19wn' }],
  ['path', { d: 'm12 15-3 5', key: '1c5kej' }],
  ['path', { d: 'm12 15 3 5', key: 'odkmhi' }],
]);

export default Tent;
