import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Layers
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cG9seWdvbiBwb2ludHM9IjEyIDIgMiA3IDEyIDEyIDIyIDcgMTIgMiIgLz4KICA8cG9seWxpbmUgcG9pbnRzPSIyIDE3IDEyIDIyIDIyIDE3IiAvPgogIDxwb2x5bGluZSBwb2ludHM9IjIgMTIgMTIgMTcgMjIgMTIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/layers
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Layers = createLucideIcon('Layers', [
  ['polygon', { points: '12 2 2 7 12 12 22 7 12 2', key: '1b0ttc' }],
  ['polyline', { points: '2 17 12 22 22 17', key: 'imjtdl' }],
  ['polyline', { points: '2 12 12 17 22 12', key: '5dexcv' }],
]);

export default Layers;
