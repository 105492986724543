import createLucideIcon from '../createLucideIcon';

/**
 * @component @name AlignHorizontalJustifyCenter
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iNiIgaGVpZ2h0PSIxNCIgeD0iMiIgeT0iNSIgcng9IjIiIC8+CiAgPHJlY3Qgd2lkdGg9IjYiIGhlaWdodD0iMTAiIHg9IjE2IiB5PSI3IiByeD0iMiIgLz4KICA8cGF0aCBkPSJNMTIgMnYyMCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/align-horizontal-justify-center
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const AlignHorizontalJustifyCenter = createLucideIcon(
  'AlignHorizontalJustifyCenter',
  [
    [
      'rect',
      { width: '6', height: '14', x: '2', y: '5', rx: '2', key: 'dy24zr' },
    ],
    [
      'rect',
      { width: '6', height: '10', x: '16', y: '7', rx: '2', key: '13zkjt' },
    ],
    ['path', { d: 'M12 2v20', key: 't6zp3m' }],
  ],
);

export default AlignHorizontalJustifyCenter;
