import createLucideIcon from '../createLucideIcon';

/**
 * @component @name GitPullRequestClosed
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxOCIgY3k9IjE4IiByPSIzIiAvPgogIDxjaXJjbGUgY3g9IjYiIGN5PSI2IiByPSIzIiAvPgogIDxwYXRoIGQ9Ik0xOCAxMS41VjE1IiAvPgogIDxwYXRoIGQ9Im0yMSAzLTYgNiIgLz4KICA8cGF0aCBkPSJtMjEgOS02LTYiIC8+CiAgPGxpbmUgeDE9IjYiIHgyPSI2IiB5MT0iOSIgeTI9IjIxIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/git-pull-request-closed
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const GitPullRequestClosed = createLucideIcon('GitPullRequestClosed', [
  ['circle', { cx: '18', cy: '18', r: '3', key: '1xkwt0' }],
  ['circle', { cx: '6', cy: '6', r: '3', key: '1lh9wr' }],
  ['path', { d: 'M18 11.5V15', key: '65xf6f' }],
  ['path', { d: 'm21 3-6 6', key: '16nqsk' }],
  ['path', { d: 'm21 9-6-6', key: '9j17rh' }],
  ['line', { x1: '6', x2: '6', y1: '9', y2: '21', key: 'rroup' }],
]);

export default GitPullRequestClosed;
