import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Sticker
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTUuNSAzSDVhMiAyIDAgMCAwLTIgMnYxNGMwIDEuMS45IDIgMiAyaDE0YTIgMiAwIDAgMCAyLTJWOC41TDE1LjUgM1oiIC8+CiAgPHBhdGggZD0iTTE1IDN2Nmg2IiAvPgogIDxwYXRoIGQ9Ik0xMCAxNnMuOCAxIDIgMWMxLjMgMCAyLTEgMi0xIiAvPgogIDxwYXRoIGQ9Ik04IDEzaDAiIC8+CiAgPHBhdGggZD0iTTE2IDEzaDAiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/sticker
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Sticker = createLucideIcon('Sticker', [
  [
    'path',
    {
      d: 'M15.5 3H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V8.5L15.5 3Z',
      key: '1wis1t',
    },
  ],
  ['path', { d: 'M15 3v6h6', key: 'edgan2' }],
  ['path', { d: 'M10 16s.8 1 2 1c1.3 0 2-1 2-1', key: '1vvgv3' }],
  ['path', { d: 'M8 13h0', key: 'jdup5h' }],
  ['path', { d: 'M16 13h0', key: 'l4i2ga' }],
]);

export default Sticker;
