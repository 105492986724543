import createLucideIcon from '../createLucideIcon';

/**
 * @component @name MonitorUp
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtOSAxMCAzLTMgMyAzIiAvPgogIDxwYXRoIGQ9Ik0xMiAxM1Y3IiAvPgogIDxyZWN0IHdpZHRoPSIyMCIgaGVpZ2h0PSIxNCIgeD0iMiIgeT0iMyIgcng9IjIiIC8+CiAgPHBhdGggZD0iTTEyIDE3djQiIC8+CiAgPHBhdGggZD0iTTggMjFoOCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/monitor-up
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const MonitorUp = createLucideIcon('MonitorUp', [
  ['path', { d: 'm9 10 3-3 3 3', key: '11gsxs' }],
  ['path', { d: 'M12 13V7', key: 'h0r20n' }],
  [
    'rect',
    { width: '20', height: '14', x: '2', y: '3', rx: '2', key: '48i651' },
  ],
  ['path', { d: 'M12 17v4', key: '1riwvh' }],
  ['path', { d: 'M8 21h8', key: '1ev6f3' }],
]);

export default MonitorUp;
