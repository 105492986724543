import createLucideIcon from '../createLucideIcon';

/**
 * @component @name BrainCog
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgNC41YTIuNSAyLjUgMCAwIDAtNC45Ni0uNDYgMi41IDIuNSAwIDAgMC0xLjk4IDMgMi41IDIuNSAwIDAgMC0xLjMyIDQuMjQgMyAzIDAgMCAwIC4zNCA1LjU4IDIuNSAyLjUgMCAwIDAgMi45NiAzLjA4QTIuNSAyLjUgMCAwIDAgOS41IDIyYzEuMjEgMCAyLjUtLjc0IDIuNS0yLjVtMC0xNWEyLjUgMi41IDAgMCAxIDQuOTYtLjQ2IDIuNSAyLjUgMCAwIDEgMS45OCAzIDIuNSAyLjUgMCAwIDEgMS4zMiA0LjI0IDMgMyAwIDAgMS0uMzQgNS41OCAyLjUgMi41IDAgMCAxLTIuOTYgMy4wOEEyLjUgMi41IDAgMCAxIDE0LjUgMjJjLTEuMjEgMC0yLjUtLjc0LTIuNS0yLjVtMC0xNVY1bTAgMTQuNVYxOSIgLz4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIyIiAvPgogIDxwYXRoIGQ9Ik0xMiA5djEiIC8+CiAgPHBhdGggZD0iTTEyIDE0djEiIC8+CiAgPHBhdGggZD0ibTE0LjYgMTAuNS0uODcuNSIgLz4KICA8cGF0aCBkPSJtMTAuMjcgMTMtLjg3LjUiIC8+CiAgPHBhdGggZD0ibTE0LjYgMTMuNS0uODctLjUiIC8+CiAgPHBhdGggZD0ibTEwLjI3IDExLS44Ny0uNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/brain-cog
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const BrainCog = createLucideIcon('BrainCog', [
  [
    'path',
    {
      d: 'M12 4.5a2.5 2.5 0 0 0-4.96-.46 2.5 2.5 0 0 0-1.98 3 2.5 2.5 0 0 0-1.32 4.24 3 3 0 0 0 .34 5.58 2.5 2.5 0 0 0 2.96 3.08A2.5 2.5 0 0 0 9.5 22c1.21 0 2.5-.74 2.5-2.5m0-15a2.5 2.5 0 0 1 4.96-.46 2.5 2.5 0 0 1 1.98 3 2.5 2.5 0 0 1 1.32 4.24 3 3 0 0 1-.34 5.58 2.5 2.5 0 0 1-2.96 3.08A2.5 2.5 0 0 1 14.5 22c-1.21 0-2.5-.74-2.5-2.5m0-15V5m0 14.5V19',
      key: '1s39q3',
    },
  ],
  ['circle', { cx: '12', cy: '12', r: '2', key: '1c9p78' }],
  ['path', { d: 'M12 9v1', key: 'pby5my' }],
  ['path', { d: 'M12 14v1', key: 'eiwm5y' }],
  ['path', { d: 'm14.6 10.5-.87.5', key: 'pm6qkn' }],
  ['path', { d: 'm10.27 13-.87.5', key: '1h0pla' }],
  ['path', { d: 'm14.6 13.5-.87-.5', key: '1pggcy' }],
  ['path', { d: 'm10.27 11-.87-.5', key: '1d8xkg' }],
]);

export default BrainCog;
