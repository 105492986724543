import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FolderHeart
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTEgMjBINGEyIDIgMCAwIDEtMi0yVjVjMC0xLjEuOS0yIDItMmgzLjkzYTIgMiAwIDAgMSAxLjY2LjlsLjgyIDEuMmEyIDIgMCAwIDAgMS42Ni45SDIwYTIgMiAwIDAgMSAyIDJ2MS41IiAvPgogIDxwYXRoIGQ9Ik0yMS4yOSAxMy43YTIuNDMgMi40MyAwIDAgMC0yLjY1LS41MmMtLjMuMTItLjU3LjMtLjguNTNsLS4zNC4zNC0uMzUtLjM0YTIuNDMgMi40MyAwIDAgMC0yLjY1LS41M2MtLjMuMTItLjU2LjMtLjc5LjUzLS45NS45NC0xIDIuNTMuMiAzLjc0TDE3LjUgMjFsMy42LTMuNTVjMS4yLTEuMjEgMS4xNC0yLjguMTktMy43NFoiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/folder-heart
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FolderHeart = createLucideIcon('FolderHeart', [
  [
    'path',
    {
      d: 'M11 20H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h3.93a2 2 0 0 1 1.66.9l.82 1.2a2 2 0 0 0 1.66.9H20a2 2 0 0 1 2 2v1.5',
      key: 'zoglpu',
    },
  ],
  [
    'path',
    {
      d: 'M21.29 13.7a2.43 2.43 0 0 0-2.65-.52c-.3.12-.57.3-.8.53l-.34.34-.35-.34a2.43 2.43 0 0 0-2.65-.53c-.3.12-.56.3-.79.53-.95.94-1 2.53.2 3.74L17.5 21l3.6-3.55c1.2-1.21 1.14-2.8.19-3.74Z',
      key: '2tfff6',
    },
  ],
]);

export default FolderHeart;
