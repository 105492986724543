import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FileEdit
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAxMy41VjRhMiAyIDAgMCAxIDItMmg4LjVMMjAgNy41VjIwYTIgMiAwIDAgMS0yIDJoLTUuNSIgLz4KICA8cG9seWxpbmUgcG9pbnRzPSIxNCAyIDE0IDggMjAgOCIgLz4KICA8cGF0aCBkPSJNMTAuNDIgMTIuNjFhMi4xIDIuMSAwIDEgMSAyLjk3IDIuOTdMNy45NSAyMSA0IDIybC45OS0zLjk1IDUuNDMtNS40NFoiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/file-edit
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FileEdit = createLucideIcon('FileEdit', [
  [
    'path',
    {
      d: 'M4 13.5V4a2 2 0 0 1 2-2h8.5L20 7.5V20a2 2 0 0 1-2 2h-5.5',
      key: '1bg6eb',
    },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  [
    'path',
    {
      d: 'M10.42 12.61a2.1 2.1 0 1 1 2.97 2.97L7.95 21 4 22l.99-3.95 5.43-5.44Z',
      key: '1rgxu8',
    },
  ],
]);

export default FileEdit;
