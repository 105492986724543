import createLucideIcon from '../createLucideIcon';

/**
 * @component @name TreeDeciduous
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOCAxOWg4YTQgNCAwIDAgMCAzLjgtMi44IDQgNCAwIDAgMC0xLjYtNC41YzEtMS4xIDEtMi43LjQtNC0uNy0xLjItMi4yLTItMy42LTEuN2EzIDMgMCAwIDAtMy0zIDMgMyAwIDAgMC0zIDNjLTEuNC0uMi0yLjkuNS0zLjYgMS43LS43IDEuMy0uNSAyLjkuNCA0YTQgNCAwIDAgMC0xLjYgNC41QTQgNCAwIDAgMCA4IDE5WiIgLz4KICA8cGF0aCBkPSJNMTIgMTl2MyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/tree-deciduous
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const TreeDeciduous = createLucideIcon('TreeDeciduous', [
  [
    'path',
    {
      d: 'M8 19h8a4 4 0 0 0 3.8-2.8 4 4 0 0 0-1.6-4.5c1-1.1 1-2.7.4-4-.7-1.2-2.2-2-3.6-1.7a3 3 0 0 0-3-3 3 3 0 0 0-3 3c-1.4-.2-2.9.5-3.6 1.7-.7 1.3-.5 2.9.4 4a4 4 0 0 0-1.6 4.5A4 4 0 0 0 8 19Z',
      key: '12ivfl',
    },
  ],
  ['path', { d: 'M12 19v3', key: 'npa21l' }],
]);

export default TreeDeciduous;
