import createLucideIcon from '../createLucideIcon';

/**
 * @component @name DatabaseBackup
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8ZWxsaXBzZSBjeD0iMTIiIGN5PSI1IiByeD0iOSIgcnk9IjMiIC8+CiAgPHBhdGggZD0iTTMgNXYxNGMwIDEuNCAzIDIuNyA3IDMiIC8+CiAgPHBhdGggZD0iTTMgMTJjMCAxLjIgMiAyLjUgNSAzIiAvPgogIDxwYXRoIGQ9Ik0yMSA1djQiIC8+CiAgPHBhdGggZD0iTTEzIDIwYTUgNSAwIDAgMCA5LTMgNC41IDQuNSAwIDAgMC00LjUtNC41Yy0xLjMzIDAtMi41NC41NC0zLjQxIDEuNDFMMTIgMTYiIC8+CiAgPHBhdGggZD0iTTEyIDEydjRoNCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/database-backup
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const DatabaseBackup = createLucideIcon('DatabaseBackup', [
  ['ellipse', { cx: '12', cy: '5', rx: '9', ry: '3', key: 'msslwz' }],
  ['path', { d: 'M3 5v14c0 1.4 3 2.7 7 3', key: 'jgylly' }],
  ['path', { d: 'M3 12c0 1.2 2 2.5 5 3', key: 'vxrdms' }],
  ['path', { d: 'M21 5v4', key: '1vq2e7' }],
  [
    'path',
    {
      d: 'M13 20a5 5 0 0 0 9-3 4.5 4.5 0 0 0-4.5-4.5c-1.33 0-2.54.54-3.41 1.41L12 16',
      key: '1f4ei9',
    },
  ],
  ['path', { d: 'M12 12v4h4', key: '1bxaet' }],
]);

export default DatabaseBackup;
