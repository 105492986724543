import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Heading5
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAxMmg4IiAvPgogIDxwYXRoIGQ9Ik00IDE4VjYiIC8+CiAgPHBhdGggZD0iTTEyIDE4VjYiIC8+CiAgPHBhdGggZD0iTTE3IDEzdi0zaDQiIC8+CiAgPHBhdGggZD0iTTE3IDE3LjdjLjQuMi44LjMgMS4zLjMgMS41IDAgMi43LTEuMSAyLjctMi41UzE5LjggMTMgMTguMyAxM0gxNyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/heading-5
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Heading5 = createLucideIcon('Heading5', [
  ['path', { d: 'M4 12h8', key: '17cfdx' }],
  ['path', { d: 'M4 18V6', key: '1rz3zl' }],
  ['path', { d: 'M12 18V6', key: 'zqpxq5' }],
  ['path', { d: 'M17 13v-3h4', key: '1nvgqp' }],
  [
    'path',
    {
      d: 'M17 17.7c.4.2.8.3 1.3.3 1.5 0 2.7-1.1 2.7-2.5S19.8 13 18.3 13H17',
      key: '2nebdn',
    },
  ],
]);

export default Heading5;
