import createLucideIcon from '../createLucideIcon';

/**
 * @component @name PartyPopper
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNS44IDExLjMgMiAyMmwxMC43LTMuNzkiIC8+CiAgPHBhdGggZD0iTTQgM2guMDEiIC8+CiAgPHBhdGggZD0iTTIyIDhoLjAxIiAvPgogIDxwYXRoIGQ9Ik0xNSAyaC4wMSIgLz4KICA8cGF0aCBkPSJNMjIgMjBoLjAxIiAvPgogIDxwYXRoIGQ9Im0yMiAyLTIuMjQuNzVhMi45IDIuOSAwIDAgMC0xLjk2IDMuMTJ2MGMuMS44Ni0uNTcgMS42My0xLjQ1IDEuNjNoLS4zOGMtLjg2IDAtMS42LjYtMS43NiAxLjQ0TDE0IDEwIiAvPgogIDxwYXRoIGQ9Im0yMiAxMy0uODItLjMzYy0uODYtLjM0LTEuODIuMi0xLjk4IDEuMTF2MGMtLjExLjctLjcyIDEuMjItMS40MyAxLjIySDE3IiAvPgogIDxwYXRoIGQ9Im0xMSAyIC4zMy44MmMuMzQuODYtLjIgMS44Mi0xLjExIDEuOTh2MEM5LjUyIDQuOSA5IDUuNTIgOSA2LjIzVjciIC8+CiAgPHBhdGggZD0iTTExIDEzYzEuOTMgMS45MyAyLjgzIDQuMTcgMiA1LS44My44My0zLjA3LS4wNy01LTItMS45My0xLjkzLTIuODMtNC4xNy0yLTUgLjgzLS44MyAzLjA3LjA3IDUgMloiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/party-popper
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const PartyPopper = createLucideIcon('PartyPopper', [
  ['path', { d: 'M5.8 11.3 2 22l10.7-3.79', key: 'gwxi1d' }],
  ['path', { d: 'M4 3h.01', key: '1vcuye' }],
  ['path', { d: 'M22 8h.01', key: '1mrtc2' }],
  ['path', { d: 'M15 2h.01', key: '1cjtqr' }],
  ['path', { d: 'M22 20h.01', key: '1mrys2' }],
  [
    'path',
    {
      d: 'm22 2-2.24.75a2.9 2.9 0 0 0-1.96 3.12v0c.1.86-.57 1.63-1.45 1.63h-.38c-.86 0-1.6.6-1.76 1.44L14 10',
      key: 'bpx1uq',
    },
  ],
  [
    'path',
    {
      d: 'm22 13-.82-.33c-.86-.34-1.82.2-1.98 1.11v0c-.11.7-.72 1.22-1.43 1.22H17',
      key: '1pd0s7',
    },
  ],
  [
    'path',
    {
      d: 'm11 2 .33.82c.34.86-.2 1.82-1.11 1.98v0C9.52 4.9 9 5.52 9 6.23V7',
      key: 'zq5xbz',
    },
  ],
  [
    'path',
    {
      d: 'M11 13c1.93 1.93 2.83 4.17 2 5-.83.83-3.07-.07-5-2-1.93-1.93-2.83-4.17-2-5 .83-.83 3.07.07 5 2Z',
      key: '4kbmks',
    },
  ],
]);

export default PartyPopper;
