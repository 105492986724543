import createLucideIcon from '../createLucideIcon';

/**
 * @component @name CloudCog
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjAgMTYuMkE0LjUgNC41IDAgMCAwIDE3LjUgOGgtMS44QTcgNyAwIDEgMCA0IDE0LjkiIC8+CiAgPGNpcmNsZSBjeD0iMTIiIGN5PSIxNyIgcj0iMyIgLz4KICA8cGF0aCBkPSJNMTIgMTN2MSIgLz4KICA8cGF0aCBkPSJNMTIgMjB2MSIgLz4KICA8cGF0aCBkPSJNMTYgMTdoLTEiIC8+CiAgPHBhdGggZD0iTTkgMTdIOCIgLz4KICA8cGF0aCBkPSJtMTUgMTQtLjg4Ljg4IiAvPgogIDxwYXRoIGQ9Ik05Ljg4IDE5LjEyIDkgMjAiIC8+CiAgPHBhdGggZD0ibTE1IDIwLS44OC0uODgiIC8+CiAgPHBhdGggZD0iTTkuODggMTQuODggOSAxNCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/cloud-cog
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const CloudCog = createLucideIcon('CloudCog', [
  [
    'path',
    { d: 'M20 16.2A4.5 4.5 0 0 0 17.5 8h-1.8A7 7 0 1 0 4 14.9', key: '19hoja' },
  ],
  ['circle', { cx: '12', cy: '17', r: '3', key: '1spfwm' }],
  ['path', { d: 'M12 13v1', key: '176q98' }],
  ['path', { d: 'M12 20v1', key: '1wcdkc' }],
  ['path', { d: 'M16 17h-1', key: 'y560le' }],
  ['path', { d: 'M9 17H8', key: '1lfe9z' }],
  ['path', { d: 'm15 14-.88.88', key: '12ytk1' }],
  ['path', { d: 'M9.88 19.12 9 20', key: '1kmb4r' }],
  ['path', { d: 'm15 20-.88-.88', key: '1ipjcf' }],
  ['path', { d: 'M9.88 14.88 9 14', key: 'c4uok7' }],
]);

export default CloudCog;
