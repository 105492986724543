import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FileVideo2
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCA4VjRhMiAyIDAgMCAxIDItMmg4LjVMMjAgNy41VjIwYTIgMiAwIDAgMS0yIDJINCIgLz4KICA8cG9seWxpbmUgcG9pbnRzPSIxNCAyIDE0IDggMjAgOCIgLz4KICA8cGF0aCBkPSJtMTAgMTUuNSA0IDIuNXYtNmwtNCAyLjUiIC8+CiAgPHJlY3Qgd2lkdGg9IjgiIGhlaWdodD0iNiIgeD0iMiIgeT0iMTIiIHJ4PSIxIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/file-video-2
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FileVideo2 = createLucideIcon('FileVideo2', [
  [
    'path',
    { d: 'M4 8V4a2 2 0 0 1 2-2h8.5L20 7.5V20a2 2 0 0 1-2 2H4', key: '1nti49' },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'm10 15.5 4 2.5v-6l-4 2.5', key: 't7cp39' }],
  [
    'rect',
    { width: '8', height: '6', x: '2', y: '12', rx: '1', key: '1a6c1e' },
  ],
]);

export default FileVideo2;
