import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FolderArchive
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjIgMjBWOGEyIDIgMCAwIDAtMi0yaC03LjkzYTIgMiAwIDAgMS0xLjY2LS45bC0uODItMS4yQTIgMiAwIDAgMCA3LjkzIDNINGEyIDIgMCAwIDAtMiAydjEzYzAgMS4xLjkgMiAyIDJoNiIgLz4KICA8Y2lyY2xlIGN4PSIxNiIgY3k9IjE5IiByPSIyIiAvPgogIDxwYXRoIGQ9Ik0xNiAxMXYtMSIgLz4KICA8cGF0aCBkPSJNMTYgMTd2LTIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/folder-archive
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FolderArchive = createLucideIcon('FolderArchive', [
  [
    'path',
    {
      d: 'M22 20V8a2 2 0 0 0-2-2h-7.93a2 2 0 0 1-1.66-.9l-.82-1.2A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13c0 1.1.9 2 2 2h6',
      key: '1l0vpk',
    },
  ],
  ['circle', { cx: '16', cy: '19', r: '2', key: '1uwppb' }],
  ['path', { d: 'M16 11v-1', key: 'eoyjtm' }],
  ['path', { d: 'M16 17v-2', key: '1xp69b' }],
]);

export default FolderArchive;
