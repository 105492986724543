import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Siren
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNyAxMmE1IDUgMCAwIDEgNS01djBhNSA1IDAgMCAxIDUgNXY2SDd2LTZaIiAvPgogIDxwYXRoIGQ9Ik01IDIwYTIgMiAwIDAgMSAyLTJoMTBhMiAyIDAgMCAxIDIgMnYySDV2LTJaIiAvPgogIDxwYXRoIGQ9Ik0yMSAxMmgxIiAvPgogIDxwYXRoIGQ9Ik0xOC41IDQuNSAxOCA1IiAvPgogIDxwYXRoIGQ9Ik0yIDEyaDEiIC8+CiAgPHBhdGggZD0iTTEyIDJ2MSIgLz4KICA8cGF0aCBkPSJtNC45MjkgNC45MjkuNzA3LjcwNyIgLz4KICA8cGF0aCBkPSJNMTIgMTJ2NiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/siren
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Siren = createLucideIcon('Siren', [
  ['path', { d: 'M7 12a5 5 0 0 1 5-5v0a5 5 0 0 1 5 5v6H7v-6Z', key: 'rmc51c' }],
  [
    'path',
    { d: 'M5 20a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v2H5v-2Z', key: 'yyvmjy' },
  ],
  ['path', { d: 'M21 12h1', key: 'jtio3y' }],
  ['path', { d: 'M18.5 4.5 18 5', key: 'g5sp9y' }],
  ['path', { d: 'M2 12h1', key: '1uaihz' }],
  ['path', { d: 'M12 2v1', key: '11qlp1' }],
  ['path', { d: 'm4.929 4.929.707.707', key: '1i51kw' }],
  ['path', { d: 'M12 12v6', key: '3ahymv' }],
]);

export default Siren;
