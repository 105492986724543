import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FolderCog2
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAyMGgxNmEyIDIgMCAwIDAgMi0yVjhhMiAyIDAgMCAwLTItMmgtNy45M2EyIDIgMCAwIDEtMS42Ni0uOWwtLjgyLTEuMkEyIDIgMCAwIDAgNy45MyAzSDRhMiAyIDAgMCAwLTIgMnYxM2MwIDEuMS45IDIgMiAyWiIgLz4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEzIiByPSIyIiAvPgogIDxwYXRoIGQ9Ik0xMiAxMHYxIiAvPgogIDxwYXRoIGQ9Ik0xMiAxNXYxIiAvPgogIDxwYXRoIGQ9Im0xNC42IDExLjUtLjg3LjUiIC8+CiAgPHBhdGggZD0ibTEwLjI3IDE0LS44Ny41IiAvPgogIDxwYXRoIGQ9Im0xNC42IDE0LjUtLjg3LS41IiAvPgogIDxwYXRoIGQ9Im0xMC4yNyAxMi0uODctLjUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/folder-cog-2
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FolderCog2 = createLucideIcon('FolderCog2', [
  [
    'path',
    {
      d: 'M4 20h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.93a2 2 0 0 1-1.66-.9l-.82-1.2A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13c0 1.1.9 2 2 2Z',
      key: '1fr9dc',
    },
  ],
  ['circle', { cx: '12', cy: '13', r: '2', key: '1c1ljs' }],
  ['path', { d: 'M12 10v1', key: 'ngorzm' }],
  ['path', { d: 'M12 15v1', key: '1ovrzm' }],
  ['path', { d: 'm14.6 11.5-.87.5', key: 'zm6w6e' }],
  ['path', { d: 'm10.27 14-.87.5', key: 'idea33' }],
  ['path', { d: 'm14.6 14.5-.87-.5', key: '1ii18h' }],
  ['path', { d: 'm10.27 12-.87-.5', key: 'tf2vd0' }],
]);

export default FolderCog2;
