import createLucideIcon from '../createLucideIcon';

/**
 * @component @name MousePointerClick
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtOSA5IDUgMTIgMS43NzQtNS4yMjZMMjEgMTQgOSA5eiIgLz4KICA8cGF0aCBkPSJtMTYuMDcxIDE2LjA3MSA0LjI0MyA0LjI0MyIgLz4KICA8cGF0aCBkPSJtNy4xODggMi4yMzkuNzc3IDIuODk3TTUuMTM2IDcuOTY1bC0yLjg5OC0uNzc3TTEzLjk1IDQuMDVsLTIuMTIyIDIuMTIybS01LjY1NyA1LjY1Ni0yLjEyIDIuMTIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/mouse-pointer-click
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const MousePointerClick = createLucideIcon('MousePointerClick', [
  ['path', { d: 'm9 9 5 12 1.774-5.226L21 14 9 9z', key: '1qd44z' }],
  ['path', { d: 'm16.071 16.071 4.243 4.243', key: 'wfhsjb' }],
  [
    'path',
    {
      d: 'm7.188 2.239.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656-2.12 2.122',
      key: '1bk8fz',
    },
  ],
]);

export default MousePointerClick;
