import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Truck
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMTdoNFY1SDJ2MTJoMyIgLz4KICA8cGF0aCBkPSJNMjAgMTdoMnYtMy4zNGE0IDQgMCAwIDAtMS4xNy0yLjgzTDE5IDloLTUiIC8+CiAgPHBhdGggZD0iTTE0IDE3aDEiIC8+CiAgPGNpcmNsZSBjeD0iNy41IiBjeT0iMTcuNSIgcj0iMi41IiAvPgogIDxjaXJjbGUgY3g9IjE3LjUiIGN5PSIxNy41IiByPSIyLjUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/truck
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Truck = createLucideIcon('Truck', [
  ['path', { d: 'M10 17h4V5H2v12h3', key: '1jq12e' }],
  ['path', { d: 'M20 17h2v-3.34a4 4 0 0 0-1.17-2.83L19 9h-5', key: '1xb3ft' }],
  ['path', { d: 'M14 17h1', key: 'nufu4t' }],
  ['circle', { cx: '7.5', cy: '17.5', r: '2.5', key: 'a7aife' }],
  ['circle', { cx: '17.5', cy: '17.5', r: '2.5', key: '1mdrzq' }],
]);

export default Truck;
