import createLucideIcon from '../createLucideIcon';

/**
 * @component @name ShieldClose
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMjJzOC00IDgtMTBWNWwtOC0zLTggM3Y3YzAgNiA4IDEwIDggMTB6IiAvPgogIDxsaW5lIHgxPSI5LjUiIHgyPSIxNC41IiB5MT0iOSIgeTI9IjE0IiAvPgogIDxsaW5lIHgxPSIxNC41IiB4Mj0iOS41IiB5MT0iOSIgeTI9IjE0IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/shield-close
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const ShieldClose = createLucideIcon('ShieldClose', [
  ['path', { d: 'M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z', key: '3xmgem' }],
  ['line', { x1: '9.5', x2: '14.5', y1: '9', y2: '14', key: '10ywql' }],
  ['line', { x1: '14.5', x2: '9.5', y1: '9', y2: '14', key: 'n3a697' }],
]);

export default ShieldClose;
