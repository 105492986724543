import createLucideIcon from '../createLucideIcon';

/**
 * @component @name CalendarX
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHg9IjMiIHk9IjQiIHJ4PSIyIiByeT0iMiIgLz4KICA8bGluZSB4MT0iMTYiIHgyPSIxNiIgeTE9IjIiIHkyPSI2IiAvPgogIDxsaW5lIHgxPSI4IiB4Mj0iOCIgeTE9IjIiIHkyPSI2IiAvPgogIDxsaW5lIHgxPSIzIiB4Mj0iMjEiIHkxPSIxMCIgeTI9IjEwIiAvPgogIDxsaW5lIHgxPSIxMCIgeDI9IjE0IiB5MT0iMTQiIHkyPSIxOCIgLz4KICA8bGluZSB4MT0iMTQiIHgyPSIxMCIgeTE9IjE0IiB5Mj0iMTgiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/calendar-x
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const CalendarX = createLucideIcon('CalendarX', [
  [
    'rect',
    {
      width: '18',
      height: '18',
      x: '3',
      y: '4',
      rx: '2',
      ry: '2',
      key: 'eu3xkr',
    },
  ],
  ['line', { x1: '16', x2: '16', y1: '2', y2: '6', key: 'm3sa8f' }],
  ['line', { x1: '8', x2: '8', y1: '2', y2: '6', key: '18kwsl' }],
  ['line', { x1: '3', x2: '21', y1: '10', y2: '10', key: 'xt86sb' }],
  ['line', { x1: '10', x2: '14', y1: '14', y2: '18', key: '1g3qc0' }],
  ['line', { x1: '14', x2: '10', y1: '14', y2: '18', key: '1az83m' }],
]);

export default CalendarX;
