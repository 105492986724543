import createLucideIcon from '../createLucideIcon';

/**
 * @component @name RussianRuble
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTQgMTFjNS4zMzMgMCA1LjMzMy04IDAtOCIgLz4KICA8cGF0aCBkPSJNNiAxMWg4IiAvPgogIDxwYXRoIGQ9Ik02IDE1aDgiIC8+CiAgPHBhdGggZD0iTTkgMjFWMyIgLz4KICA8cGF0aCBkPSJNOSAzaDUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/russian-ruble
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const RussianRuble = createLucideIcon('RussianRuble', [
  ['path', { d: 'M14 11c5.333 0 5.333-8 0-8', key: '92e629' }],
  ['path', { d: 'M6 11h8', key: '1cr2u4' }],
  ['path', { d: 'M6 15h8', key: '1y8f6l' }],
  ['path', { d: 'M9 21V3', key: '1jd2g6' }],
  ['path', { d: 'M9 3h5', key: '8bgvcw' }],
]);

export default RussianRuble;
