import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FolderCog
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAuNSAyMEg0YTIgMiAwIDAgMS0yLTJWNWMwLTEuMS45LTIgMi0yaDMuOTNhMiAyIDAgMCAxIDEuNjYuOWwuODIgMS4yYTIgMiAwIDAgMCAxLjY2LjlIMjBhMiAyIDAgMCAxIDIgMnYzIiAvPgogIDxjaXJjbGUgY3g9IjE4IiBjeT0iMTgiIHI9IjMiIC8+CiAgPHBhdGggZD0iTTE4IDE0djEiIC8+CiAgPHBhdGggZD0iTTE4IDIxdjEiIC8+CiAgPHBhdGggZD0iTTIyIDE4aC0xIiAvPgogIDxwYXRoIGQ9Ik0xNSAxOGgtMSIgLz4KICA8cGF0aCBkPSJtMjEgMTUtLjg4Ljg4IiAvPgogIDxwYXRoIGQ9Ik0xNS44OCAyMC4xMiAxNSAyMSIgLz4KICA8cGF0aCBkPSJtMjEgMjEtLjg4LS44OCIgLz4KICA8cGF0aCBkPSJNMTUuODggMTUuODggMTUgMTUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/folder-cog
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FolderCog = createLucideIcon('FolderCog', [
  [
    'path',
    {
      d: 'M10.5 20H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h3.93a2 2 0 0 1 1.66.9l.82 1.2a2 2 0 0 0 1.66.9H20a2 2 0 0 1 2 2v3',
      key: '1g1zaq',
    },
  ],
  ['circle', { cx: '18', cy: '18', r: '3', key: '1xkwt0' }],
  ['path', { d: 'M18 14v1', key: '1sx9hk' }],
  ['path', { d: 'M18 21v1', key: 'pviwm2' }],
  ['path', { d: 'M22 18h-1', key: 'phgwqy' }],
  ['path', { d: 'M15 18h-1', key: '1v9fvv' }],
  ['path', { d: 'm21 15-.88.88', key: '13nfy4' }],
  ['path', { d: 'M15.88 20.12 15 21', key: 'qplfkl' }],
  ['path', { d: 'm21 21-.88-.88', key: '1ryrr1' }],
  ['path', { d: 'M15.88 15.88 15 15', key: '4terp3' }],
]);

export default FolderCog;
