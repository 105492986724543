import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Bug
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgeD0iOCIgeT0iNiIgcng9IjQiIC8+CiAgPHBhdGggZD0ibTE5IDctMyAyIiAvPgogIDxwYXRoIGQ9Im01IDcgMyAyIiAvPgogIDxwYXRoIGQ9Im0xOSAxOS0zLTIiIC8+CiAgPHBhdGggZD0ibTUgMTkgMy0yIiAvPgogIDxwYXRoIGQ9Ik0yMCAxM2gtNCIgLz4KICA8cGF0aCBkPSJNNCAxM2g0IiAvPgogIDxwYXRoIGQ9Im0xMCA0IDEgMiIgLz4KICA8cGF0aCBkPSJtMTQgNC0xIDIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/bug
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Bug = createLucideIcon('Bug', [
  [
    'rect',
    { width: '8', height: '14', x: '8', y: '6', rx: '4', key: 'hq8nra' },
  ],
  ['path', { d: 'm19 7-3 2', key: 'fmg8ec' }],
  ['path', { d: 'm5 7 3 2', key: 'dkxqes' }],
  ['path', { d: 'm19 19-3-2', key: '1hbhi4' }],
  ['path', { d: 'm5 19 3-2', key: 'dvt2ee' }],
  ['path', { d: 'M20 13h-4', key: '1agfp2' }],
  ['path', { d: 'M4 13h4', key: '1bwh8b' }],
  ['path', { d: 'm10 4 1 2', key: '1pot59' }],
  ['path', { d: 'm14 4-1 2', key: 'm8sn0o' }],
]);

export default Bug;
