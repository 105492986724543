import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FolderLock
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMjBINGEyIDIgMCAwIDEtMi0yVjVjMC0xLjEuOS0yIDItMmgzLjkzYTIgMiAwIDAgMSAxLjY2LjlsLjgyIDEuMmEyIDIgMCAwIDAgMS42Ni45SDIwYTIgMiAwIDAgMSAyIDJ2Mi41IiAvPgogIDxyZWN0IHdpZHRoPSI4IiBoZWlnaHQ9IjUiIHg9IjE0IiB5PSIxNyIgcng9IjEiIC8+CiAgPHBhdGggZD0iTTIwIDE3di0yYTIgMiAwIDEgMC00IDB2MiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/folder-lock
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FolderLock = createLucideIcon('FolderLock', [
  [
    'path',
    {
      d: 'M10 20H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h3.93a2 2 0 0 1 1.66.9l.82 1.2a2 2 0 0 0 1.66.9H20a2 2 0 0 1 2 2v2.5',
      key: '1ivsx8',
    },
  ],
  [
    'rect',
    { width: '8', height: '5', x: '14', y: '17', rx: '1', key: '19aais' },
  ],
  ['path', { d: 'M20 17v-2a2 2 0 1 0-4 0v2', key: 'pwaxnr' }],
]);

export default FolderLock;
