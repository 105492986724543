import createLucideIcon from '../createLucideIcon';

/**
 * @component @name HopOff
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTcuNSA1LjVDMTkgNyAyMC41IDkgMjEgMTFjLTEuMzIzLjI2NS0yLjY0Ni4zOS00LjExOC4yMjYiIC8+CiAgPHBhdGggZD0iTTUuNSAxNy41QzcgMTkgOSAyMC41IDExIDIxYy41LTIuNS41LTUtMS04LjUiIC8+CiAgPHBhdGggZD0iTTE3LjUgMTcuNWMtMi41IDAtNCAwLTYtMSIgLz4KICA8cGF0aCBkPSJNMjAgMTEuNWMxIDEuNSAyIDMuNSAyIDQuNSIgLz4KICA8cGF0aCBkPSJNMTEuNSAyMGMxLjUgMSAzLjUgMiA0LjUgMiAuNS0xLjUgMC0zLS41LTQuNSIgLz4KICA8cGF0aCBkPSJNMjIgMjJjLTIgMC0zLjUtLjUtNS41LTEuNSIgLz4KICA8cGF0aCBkPSJNNC43ODMgNC43ODJDMS4wNzMgOC40OTIgMSAxNC41IDUgMThjMS0xIDItNC41IDEuNS02LjUgMS41IDEgNCAxIDUuNS41TTguMjI3IDIuNTdDMTEuNTc4IDEuMzM1IDE1LjQ1MyAyLjA4OSAxOCA1Yy0uODguODgtMy43IDEuNzYxLTUuNzI2IDEuNjE4IiAvPgogIDxsaW5lIHgxPSIyIiB4Mj0iMjIiIHkxPSIyIiB5Mj0iMjIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/hop-off
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const HopOff = createLucideIcon('HopOff', [
  [
    'path',
    {
      d: 'M17.5 5.5C19 7 20.5 9 21 11c-1.323.265-2.646.39-4.118.226',
      key: '10j95a',
    },
  ],
  [
    'path',
    { d: 'M5.5 17.5C7 19 9 20.5 11 21c.5-2.5.5-5-1-8.5', key: '1mqyjd' },
  ],
  ['path', { d: 'M17.5 17.5c-2.5 0-4 0-6-1', key: '11elt5' }],
  ['path', { d: 'M20 11.5c1 1.5 2 3.5 2 4.5', key: '13ezvz' }],
  [
    'path',
    { d: 'M11.5 20c1.5 1 3.5 2 4.5 2 .5-1.5 0-3-.5-4.5', key: '1ufrz1' },
  ],
  ['path', { d: 'M22 22c-2 0-3.5-.5-5.5-1.5', key: '1n8vbj' }],
  [
    'path',
    {
      d: 'M4.783 4.782C1.073 8.492 1 14.5 5 18c1-1 2-4.5 1.5-6.5 1.5 1 4 1 5.5.5M8.227 2.57C11.578 1.335 15.453 2.089 18 5c-.88.88-3.7 1.761-5.726 1.618',
      key: '1h85u8',
    },
  ],
  ['line', { x1: '2', x2: '22', y1: '2', y2: '22', key: 'a6p6uj' }],
]);

export default HopOff;
