import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Bus
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTkgMTdoMmwuNjQtMi41NGMuMjQtLjk1OS4yNC0xLjk2MiAwLTIuOTJsLTEuMDctNC4yN0EzIDMgMCAwIDAgMTcuNjYgNUg0YTIgMiAwIDAgMC0yIDJ2MTBoMiIgLz4KICA8cGF0aCBkPSJNMTQgMTdIOSIgLz4KICA8Y2lyY2xlIGN4PSI2LjUiIGN5PSIxNy41IiByPSIyLjUiIC8+CiAgPGNpcmNsZSBjeD0iMTYuNSIgY3k9IjE3LjUiIHI9IjIuNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/bus
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Bus = createLucideIcon('Bus', [
  [
    'path',
    {
      d: 'M19 17h2l.64-2.54c.24-.959.24-1.962 0-2.92l-1.07-4.27A3 3 0 0 0 17.66 5H4a2 2 0 0 0-2 2v10h2',
      key: 'wfsdqh',
    },
  ],
  ['path', { d: 'M14 17H9', key: 'o2noo5' }],
  ['circle', { cx: '6.5', cy: '17.5', r: '2.5', key: 'gc8oob' }],
  ['circle', { cx: '16.5', cy: '17.5', r: '2.5', key: '4btu0q' }],
]);

export default Bus;
