import createLucideIcon from '../createLucideIcon';

/**
 * @component @name UserCog
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTYgMjF2LTJhNCA0IDAgMCAwLTQtNEg2YTQgNCAwIDAgMC00IDR2MiIgLz4KICA8Y2lyY2xlIGN4PSI5IiBjeT0iNyIgcj0iNCIgLz4KICA8Y2lyY2xlIGN4PSIxOSIgY3k9IjExIiByPSIyIiAvPgogIDxwYXRoIGQ9Ik0xOSA4djEiIC8+CiAgPHBhdGggZD0iTTE5IDEzdjEiIC8+CiAgPHBhdGggZD0ibTIxLjYgOS41LS44Ny41IiAvPgogIDxwYXRoIGQ9Im0xNy4yNyAxMi0uODcuNSIgLz4KICA8cGF0aCBkPSJtMjEuNiAxMi41LS44Ny0uNSIgLz4KICA8cGF0aCBkPSJtMTcuMjcgMTAtLjg3LS41IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/user-cog
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const UserCog = createLucideIcon('UserCog', [
  ['path', { d: 'M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2', key: '1yyitq' }],
  ['circle', { cx: '9', cy: '7', r: '4', key: 'nufk8' }],
  ['circle', { cx: '19', cy: '11', r: '2', key: '1rxg02' }],
  ['path', { d: 'M19 8v1', key: '1iffrw' }],
  ['path', { d: 'M19 13v1', key: 'z4xc62' }],
  ['path', { d: 'm21.6 9.5-.87.5', key: '6lxupl' }],
  ['path', { d: 'm17.27 12-.87.5', key: '1rwhxx' }],
  ['path', { d: 'm21.6 12.5-.87-.5', key: 'agvc9a' }],
  ['path', { d: 'm17.27 10-.87-.5', key: '12d57s' }],
]);

export default UserCog;
