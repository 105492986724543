import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Users2
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTQgMTlhNiA2IDAgMCAwLTEyIDAiIC8+CiAgPGNpcmNsZSBjeD0iOCIgY3k9IjkiIHI9IjQiIC8+CiAgPHBhdGggZD0iTTIyIDE5YTYgNiAwIDAgMC02LTYgNCA0IDAgMSAwIDAtOCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/users-2
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Users2 = createLucideIcon('Users2', [
  ['path', { d: 'M14 19a6 6 0 0 0-12 0', key: 'vej9p1' }],
  ['circle', { cx: '8', cy: '9', r: '4', key: '143rtg' }],
  ['path', { d: 'M22 19a6 6 0 0 0-6-6 4 4 0 1 0 0-8', key: 'hqbn22' }],
]);

export default Users2;
