import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Bomb
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMSIgY3k9IjEzIiByPSI5IiAvPgogIDxwYXRoIGQ9Im0xOS41IDkuNSAxLjgtMS44YTIuNCAyLjQgMCAwIDAgMC0zLjRsLTEuNi0xLjZhMi40MSAyLjQxIDAgMCAwLTMuNCAwbC0xLjggMS44IiAvPgogIDxwYXRoIGQ9Im0yMiAyLTEuNSAxLjUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/bomb
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Bomb = createLucideIcon('Bomb', [
  ['circle', { cx: '11', cy: '13', r: '9', key: 'hd149' }],
  [
    'path',
    {
      d: 'm19.5 9.5 1.8-1.8a2.4 2.4 0 0 0 0-3.4l-1.6-1.6a2.41 2.41 0 0 0-3.4 0l-1.8 1.8',
      key: '9owvxi',
    },
  ],
  ['path', { d: 'm22 2-1.5 1.5', key: 'ay92ug' }],
]);

export default Bomb;
