import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FileCog2
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTQuNSAySDZhMiAyIDAgMCAwLTIgMnYxNmEyIDIgMCAwIDAgMiAyaDEyYTIgMiAwIDAgMCAyLTJWNy41TDE0LjUgMnoiIC8+CiAgPHBvbHlsaW5lIHBvaW50cz0iMTQgMiAxNCA4IDIwIDgiIC8+CiAgPGNpcmNsZSBjeD0iMTIiIGN5PSIxNSIgcj0iMiIgLz4KICA8cGF0aCBkPSJNMTIgMTJ2MSIgLz4KICA8cGF0aCBkPSJNMTIgMTd2MSIgLz4KICA8cGF0aCBkPSJtMTQuNiAxMy41LS44Ny41IiAvPgogIDxwYXRoIGQ9Im0xMC4yNyAxNi0uODcuNSIgLz4KICA8cGF0aCBkPSJtMTQuNiAxNi41LS44Ny0uNSIgLz4KICA8cGF0aCBkPSJtMTAuMjcgMTQtLjg3LS41IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/file-cog-2
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FileCog2 = createLucideIcon('FileCog2', [
  [
    'path',
    {
      d: 'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      key: '1nnpy2',
    },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['circle', { cx: '12', cy: '15', r: '2', key: '1vpstw' }],
  ['path', { d: 'M12 12v1', key: '1vuyxr' }],
  ['path', { d: 'M12 17v1', key: 'y8y3f9' }],
  ['path', { d: 'm14.6 13.5-.87.5', key: 'nomeg4' }],
  ['path', { d: 'm10.27 16-.87.5', key: '1o8v95' }],
  ['path', { d: 'm14.6 16.5-.87-.5', key: 'gzu2jw' }],
  ['path', { d: 'm10.27 14-.87-.5', key: '1vlkk3' }],
]);

export default FileCog2;
