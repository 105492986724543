import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Map
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cG9seWdvbiBwb2ludHM9IjMgNiA5IDMgMTUgNiAyMSAzIDIxIDE4IDE1IDIxIDkgMTggMyAyMSIgLz4KICA8bGluZSB4MT0iOSIgeDI9IjkiIHkxPSIzIiB5Mj0iMTgiIC8+CiAgPGxpbmUgeDE9IjE1IiB4Mj0iMTUiIHkxPSI2IiB5Mj0iMjEiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/map
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Map = createLucideIcon('Map', [
  [
    'polygon',
    { points: '3 6 9 3 15 6 21 3 21 18 15 21 9 18 3 21', key: 'ok2ie8' },
  ],
  ['line', { x1: '9', x2: '9', y1: '3', y2: '18', key: 'w34qz5' }],
  ['line', { x1: '15', x2: '15', y1: '6', y2: '21', key: 'volv9a' }],
]);

export default Map;
